@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,400;1,400&display=swap');

.txt {
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 58px;
  /* identical to box height */

  letter-spacing: 0.03em;

  color: #9676A1;
}

.pText {
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  word-wrap: break-word;
  text-align: justify;
  padding: 5px;
  color: #020312;
  word-wrap: break-word;
}

.img1 {
  max-width: 500px;
  object-fit: contain;
  margin-left: 50px;

}

.img2 {
  max-width: 400px;
  max-height: 600px;
  object-fit: contain;
  margin-top: 100px;

}

.subTextTeam {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  color: #3C3C3C;
}

.golLogo {
  max-width: 250px;
  max-height: 250px;
}

.sub-con {
  margin: 10%;
  align-items: center;
  justify-content: center;
}

.m-text {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150.5%;
  text-transform: capitalize;
  color: #3C3C3C;
  text-align: justify
}

.headText {
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 120%;
  letter-spacing: 0.005em;
  color: #11142D;
}

@media only screen and (min-device-width: 800px)and (max-width: 1400px) {

  /* For mobile laptops tabs: */
  .img1 {
    max-width: 500px;
    object-fit: contain;
  }

  .img2 {
    max-width: 350px;
    object-fit: cover;
  }
}

@media only screen and (max-width: 768px) {

  /* For mobile laptops tabs: */
  .sub-con {
    margin: 1%;
    align-items: center;
    justify-content: center;
  }

  .img1 {
    margin-top: -50px;
    width: 350px;
    object-fit: cover;
  }

  .teamTxt {
    font-size: 30px;
    margin-top: -220px !important;
  }

  .img2 {
    margin-top: -100px;
    width: 350px;
    object-fit: cover;

  }

  .mobmarg {
    margin-top: 100px !important;
  }

  .headText {
    margin-top: 40px;
  }

  /* .subTextTeam {
    font-size: 12px;
    margin-left: 5px;
  } */

  .m-text {
    font-size: 12px;
  }

  .tTexts {
    /* font-size: 10px !important; */
    text-align: justify;
    line-height: normal;
    word-wrap: break-word;

  }
}