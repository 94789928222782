.homTxt {
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 600;
    font-size: 42px;
    line-height: 52px;
    color: #FFFFFF;
}

.software-products {
    position: relative;
    font-size: var(--font-size-51xl);
    letter-spacing: 0.03em;
    line-height: 52px;
    font-weight: 600;
    color: var(--color-yellowgreen-100);
}

.overlayStyle {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(5px);
    margin-top: 60px;
}

.txtArea {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20%;
}

.line-one {
    display: flex;
    margin-top: 100px;
}

.textM {
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 600;
    font-size: 42px;
    line-height: 52px;
    text-align: right;
    color: #77B02B;
}

.textV {
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 600;
    font-size: 42px;
    line-height: 52px;
    text-align: left;
    color: #77B02B;
}

.paraTxt {
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 138%;
    text-align: right;
    letter-spacing: 0.02em;
    color: #3A3A3A;
}

.top-m-txt {
    max-width: 1000px;
    margin: auto;
    margin-top: 150px;
    margin-bottom: 150px;
    margin-right: 10%;
}

.top-v-txt {
    max-width: 1000px;
    margin: auto;
    margin-top: 150px;
    margin-bottom: 150px;
    margin-left: 10%;
}

.paraTxt2 {
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    text-align: left;
    letter-spacing: 0.02em;
    color: #3A3A3A;
}

.bgtxt-con {
    /* max-width: 800px; */
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin: auto;
    text-align: center;
    align-items: center;
    justify-content: center;
    height: auto;

}

.txxxx {
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    color: #3A3A3A;

}

.subConTT {
    display: flex;
    flex-direction: column;
    max-width: 900px;

}

.bgTX {
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 600;
    font-size: 42px;
    line-height: 52px;
    text-align: center;
    color: #77B02B;
    margin-top: 800px;


}

@media only screen and (min-device-width: 800px)and (max-width: 1400px) {
    .bgTX {

        margin-top: 400px;
    }
}

@media only screen and (max-width: 768px) {
    .mobController {
        margin-top: -700px !important;
    }

    .txtArea {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 35%;
        gap: 2rem;
    }

    .line-one {
        display: flex;
        flex-direction: column;
        margin-top: 100px;
    }

    .bgControll {
        max-width: 400px;
        max-height: 500px;
        object-fit: contain;
        /* background-image: url("/homeBg.png"); */
    }

    .paraTxt2 {
        font-weight: 400;
        font-size: 18px;
        text-align: left;
        color: #3A3A3A;
        /* margin-top: 50px; */
    }

}