.main-con {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;

}

.selector {
    max-width: 400px;
    height: 300px;
    border: 1px dashed grey;
    padding: 20px;
    align-items: baseline;
    display: flex;
    flex-direction: column;
    gap: 40px;
    border-radius: 15px;
    margin-bottom: 20px;
    /* text-align: center; */
}

.sendBtn {
    background-color: #4884E6;
    color: white;

}

.txts {
    color: #4884E6;
}

.animate {}

.animate:hover {
    transform: scale(1.1);
    transition: ease all 0.5s;
}

.glow {}

.glow:hover {
    -webkit-box-shadow: 0px 0px 105px 4px #4884E6;
    -moz-box-shadow: 0px 0px 105px 4px #4884E6;
    box-shadow: 0px 0px 105px 4px #4884E6;
}