@import url("https://fonts.googleapis.com/css2?family=Urbanist:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=SF+Pro+Display:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Libre+Franklin:wght@600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Font+Awesome+5+Pro:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Font+Awesome+5+Brands:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Helvetica:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,400;1,400&display=swap');

body {
  margin: 0;
  line-height: normal;
  padding: 0;
  box-sizing: border-box;

}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.txts {
  color: #4884E6;
}

.animate {}

.animate:hover {
  transform: scale(1.1);
  transition: ease all 0.5s;
}

.glow {
  /* background-color: transparent; */
  /* overflow: hidden; */
}

.bgGreen {
  background-color: #8cc63f;
}

.glow:hover {
  -webkit-box-shadow: 0px 0px 105px 4px #4884E6;
  -moz-box-shadow: 0px 0px 105px 4px #4884E6;
  box-shadow: 0px 0px 105px 4px #4884E6;
}

.glowG {
  background-color: transparent;
  overflow: hidden;
}

.glowG:hover {
  -webkit-box-shadow: 0px 0px 105px 2px #8cc63f;
  -moz-box-shadow: 0px 0px 105px 2px #8cc63f;
  box-shadow: 0px 0px 105px 2px #8cc63f;
}

body::-webkit-scrollbar {
  width: 0.5em;
}

body::-webkit-scrollbar-track {
  background-color: transparent;
}

body::-webkit-scrollbar-thumb {
  background-color: transparent;
}

body::-webkit-scrollbar-corner {
  background-color: transparent;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

:root {
  /* fonts */
  /* font-size: 13px; */
  --h1: Urbanist;
  --menu-16px-regular: Roboto;
  --body-semibold-default-16: Inter;
  --font-poppins: Poppins;
  --font-font-awesome-5-pro: "Font Awesome 5 Pro";
  --font-libre-franklin: "Libre Franklin";
  --font-sf-pro-display: "SF Pro Display";
  --headings-bold-32: Raleway;
  --font-font-awesome-5-brands: "Font Awesome 5 Brands";
  --font-mulish: Mulish;
  --font-helvetica: Helvetica;
  --font-space-grotesk: "Space Grotesk";

  /* font sizes */
  --font-size-lg: 1.13rem;
  --font-size-4xl: 1.44rem;
  --font-size-smi: 0.81rem;
  --font-size-5xl: 1.5rem;
  --font-size-23xl: 2.63rem;
  --font-size-51xl: 4.38rem;
  --body-semibold-default-16-size: 1rem;
  --font-size-mid: 1.06rem;
  --font-size-5xs: 0.5rem;
  --headings-bold-32-size: 2rem;
  --font-size-xs: 0.75rem;
  --font-size-sm: 0.88rem;
  --font-size-3xl: 1.38rem;
  --font-size-9xl: 1.75rem;
  --font-size-17xl: 2.25rem;
  --font-size-xl: 1.25rem;
  --font-size-8xl: 1.69rem;
  --font-size-mini: 0.94rem;
  --h1-size: 3.75rem;
  --font-size-3xs: 0.63rem;
  --font-size-7xl: 1.63rem;
  --font-size-18xl: 2.31rem;
  --font-size-29xl: 3rem;
  --font-size-21xl: 2.5rem;
  --font-size-52xl: 4.44rem;

  /* Colors */
  --blueBtns: #4884E6;
  --color-white: #fff;
  --color-black: #000;
  --color-ghostwhite-100: #fcfbff;
  --color-ghostwhite-200: #fbfbff;
  --shoot2-background1: #f8f9ff;
  --color-ghostwhite-300: rgba(251, 251, 255, 0.54);
  --color-darkslategray-100: #3c3c3c;
  --color-darkslategray-200: #3a3a3a;
  --text-title-text: #333;
  --other-color-gray-background: #2f2e38;
  --text-title: #2e2e2e;
  --shoot3-black: #242b42;
  --color-yellowgreen-100: #8cc63f;
  --color-yellowgreen-200: #77b02b;
  --color-gray-100: #fdfcfc;
  --black1: #1d1e25;
  --black: #181818;
  --neutral-gray-900: #0f172a;
  --light-text-color-title: #11142d;
  --color-gray-200: rgba(0, 0, 0, 0.4);
  --color-gray-300: rgba(255, 255, 255, 0.75);
  --color-gray-500: rgba(0, 0, 0, 0.35);
  --color-gray-400: rgba(255, 255, 255, 0);
  --color-royalblue-100: #1f6eec;
  --color-royalblue-200: #2552b5;
  --color-gainsboro-100: #dfdfdf;
  --color-gainsboro-200: #d9d9d9;
  --color-dodgerblue-100: #3c8fff;
  --color-dodgerblue-200: #4884e6;
  --color-dodgerblue-300: rgba(72, 132, 230, 0.75);
  --color-dodgerblue-400: rgba(50, 136, 255, 0.33);
  --color-cornflowerblue: #7cacfb;
  --color-lavender: #c8e4ff;
  --color-dimgray-100: #606060;
  --color-dimgray-200: #545454;
  --color-lightsteelblue: #adc5e5;
  --color-lightslategray: #808d9e;

  /* Gaps */
  --gap-113xl: 8.25rem;
  --gap-21xl: 2.5rem;
  --gap-5xs: 0.5rem;
  --gap-23xl: 2.63rem;
  --gap-5xl: 1.5rem;

  /* Paddings */
  --padding-71xl: 5.63rem;
  --padding-241xl: 16.25rem;
  --padding-4xl: 1.44rem;
  --padding-864xl: 55.19rem;
  --padding-186xl: 12.81rem;
  --padding-53xl: 4.5rem;
  --padding-3xs: 0.63rem;
  --padding-2xl: 1.31rem;
  --padding-110xl: 8.06rem;
  --padding-125xl: 9rem;
  --padding-8xs: 0.31rem;

  /* border radiuses */
  --br-8xs: 5px;
  --br-lgi: 19px;
  --br-xl: 20px;
  --br-3xs: 10px;
  --br-base: 16px;
  --br-50xl: 69px;
  --br-13xl: 32px;
  --br-mid: 17px;
  --br-2xs: 11px;
  --br-7xl: 26px;
  --br-7xs: 6px;
}

/* custom */

@keyframes shake {
  0% {
    transform: translateX(0);
  }

  10% {
    transform: translateX(-5px);
  }

  20% {
    transform: translateX(5px);
  }

  30% {
    transform: translateX(-5px);
  }

  40% {
    transform: translateX(5px);
  }

  50% {
    transform: translateX(-5px);
  }

  60% {
    transform: translateX(5px);
  }

  70% {
    transform: translateX(-5px);
  }

  80% {
    transform: translateX(5px);
  }

  90% {
    transform: translateX(-5px);
  }

  100% {
    transform: translateX(0);
  }
}

.shake-element {
  margin: 10px;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  /* transform: scaleX(1.05); */
  /* transition: all 1s; */
}

.shake-element:hover {
  animation: shake 0.8s ease-in-out;
}

.pointer {
  cursor: pointer;
}

.roketImg {
  display: block;
}

.txtGlow {}

.txtGlow:hover {
  text-shadow: 0 0 3px #1f6eec;
  transform: scaleX(1.02);
  transition: all ease-in-out 1s;
}

@media only screen and (max-width: 768px) {

  /* For mobile phones: */
  .roketImg {
    /* display: none; */
    max-width: 200px;
    max-height: 55px;
    margin-left: 20px;
  }


}