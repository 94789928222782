@import url('https://fonts.googleapis.com/css2?family=Arvo:wght@400;700&family=Urbanist:ital,wght@0,100;0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,500;1,600;1,700;1,800;1,900&display=swap');

.tiredText {
    color: #3c8fff;
    font-family: 'Urbanist';
    font-style: normal;
}

.maincontainer {
    font-size: 16px;
    width: 100%;
    height: 100vh;
}

.txtCon {
    margin-left: 10%;
    margin-top: 15%;
    margin-right: 5%;
    max-width: 900px;
    align-items: center;
    justify-content: center;

    /* max-height: 500px; */
}

.sparksText {
    font-size: 64px;
    font-weight: 800;
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 600;
    margin-top: -20px;
}

.divImg {
    /* background-image: url('public/assets/images/products/productBg.png'); */
    background-size: cover;
    /* Adjust the sizing of the background image */
    background-position: center;
    /* Adjust the position of the background image */
    max-height: 1100px;
}

.mob-img {
    max-width: 700px;
    max-height: 900px;
    object-fit: cover;
    position: absolute;
    top: 25%;
    right: 5%;

}

.mob-2 {
    max-width: 1200px;
    max-height: 800px;
}

.mobMac {
    max-width: 1200px;
    max-height: 1200px;
}

.cardCon {
    max-width: 360px;
    max-height: 450px;
    object-fit: contain;
    margin-left: 15px;
    margin-top: 40px;
    padding: 5px;
    border-radius: 15px;
}

.footForm {
    max-width: 935px;
    max-height: 450px;
    object-fit: contain;
    margin: auto;
    align-self: center;

}

.getTouch {
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 600;
    text-align: center;
    color: white;
    font-size: 30px;
    text-align: center;
    top: 40%;
    margin-top: 50px;
}

.marginB200 {
    margin-bottom: 200px;
}

.footTxt {
    color: white;
    font-family: 'Arvo', serif;
    font-family: 'Urbanist', sans-serif;
    font-size: 30px;
    text-align: center;
    margin-bottom: 40px;
}

.spImg {
    max-width: 350px;
    max-height: 50px;
    align-self: center;
    justify-content: center;
    align-items: center;
}

.mainCardCon {

    display: flex;
    flex-wrap: wrap;
}

.submit-btn {
    max-width: 150px;
    max-height: 50px;
    border: none;
    border-radius: 15px;
    color: grey;
    background-color: white;
    padding: 10px;
    text-align: center;
    margin-bottom: 40px;
    margin-top: 80px;
    font-size: 20px;
    padding-bottom: 20px;
    cursor: pointer;
}

.contactInp {
    background: none;
    border: none;
    padding: 5px;
    color: white;
    outline: none;
    max-width: 350px;
    overflow: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;

}

.contactInp::-webkit-scrollbar {
    display: none;
}

.contactInp::placeholder {

    color: rgb(223, 213, 213);
}

.custom-textarea {
    color: white;
    border: none;
    outline: none;
    background-color: transparent;
    resize: none;
    width: 400px !important;

}

.logoImg {
    max-width: 300px;
    max-height: 60px;
    margin-left: 40px;

}

.header-right {
    display: flex;
    gap: 10px;
    font-size: 18px;
    background-color: white;
    font-family: 'Urbanist';
    padding: 5px;
    font-weight: bold;
    align-items: flex-end;
    justify-content: flex-end;
}

.RoketWeb {
    max-width: 200px;
    max-height: 60px;
    cursor: pointer;
    object-fit: contain;
}

.tabImg {
    max-width: 500px;
    cursor: pointer;
    max-height: 400px;
    padding: 5px;
    margin-left: 10px;
}


.form-menu {
    display: flex;
}



@media only screen and (max-width: 768px) {
    .mobMob {
        margin-left: 10px !important;
        overflow: auto;
    }

    .mobHide {
        width: 400px;
        height: 600px;
        position: relative;
    }

    .contactInp {
        background: none;
        border: none;
        padding: 2px;
        color: white;
        outline: none;
        max-width: 400px;
        overflow: auto;
        scrollbar-width: none;
        -ms-overflow-style: none;
        /* font-size: large; */
        font-size: 16px;

    }

    .contactInp::-webkit-scrollbar {
        display: none;
    }

    .contactInp::placeholder {

        color: rgb(248, 246, 246);
        font-size: 16px;
        /* Change the color to your desired value */
    }


    .form-menu {
        display: flex;
        flex-direction: column;
    }

    .mainCardCon {

        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
    }

    .footForm {
        max-width: 300px;
        max-height: 600px;
        object-fit: cover;


    }

    .getTouch {
        font-family: 'Urbanist';
        font-style: normal;
        font-weight: 600;
        text-align: center;
        color: white;
        font-size: 20px;
        text-align: center;
        top: 40%;
        margin-top: 50px;
    }

    .sparksText {
        font-size: 40px;
        font-weight: 700;
    }

    .smallTxt {
        font-size: 11px !important;
    }

    .tiredText {
        font-size: 10px !important;
    }

    .mob-img {
        max-width: 250px !important;
        max-height: 500px !important;
        object-fit: cover;
        position: absolute;
        top: 35%;
        left: 37%;

    }

    .mob-2 {
        max-width: 400px;
        max-height: 500px;
        object-fit: contain;
        margin: auto;

    }

    .tabImg {
        max-width: 150px;
        cursor: pointer;
        max-height: 150px;
        padding: 5px;
        margin-left: 12px;
    }

    .cardCon {
        max-width: 250px;
        max-height: 350px;
        object-fit: contain;
        margin: auto;
        padding: 5px;
        border-radius: 15px;
    }

    .header-right {
        display: flex;
        flex-direction: column;
        /* gap: 10px; */
        font-size: 18px;
        font-family: 'Urbanist';
        /* padding: 5px; */
        font-weight: bold;
        align-items: flex-start;
        justify-content: flex-start;
        /* background-color: rgb(99, 108, 138); */
        /* color: white; */
        /* padding-top: 40px; */
    }

    .hamburger {
        /* color: white; */
        width: 40px;
        height: 35px;
        right: 3%;
        position: absolute;
        padding-top: 1%;
        z-index: 99999;
    }

    .mobilSideBar {
        display: block;
        max-width: 200px !important;
    }
}

.mobilSideBar {
    display: none;
}

@media (min-width: 1920px) and (max-width: 2560px) {
    .midScCon {
        margin-left: 23rem;
    }
}

@media only screen and (min-device-width: 800px)and (max-width: 1600px) {

    /* For mobile laptops tabs: */
    .midScCon {
        margin-left: 23rem;
    }

    .mobilSideBar {
        display: none;
    }

    .mob-img {
        max-width: 500px;
        max-height: 700px;
        object-fit: cover;
        position: absolute;
        top: 25%;
        left: 65%;

    }

    .tiredText {
        color: #3c8fff;
        font-family: 'Urbanist';
        font-style: 10px;
        margin-left: -18%;
        font-size: 30px !important;
    }

    .footForm {
        max-width: 800px;
        max-height: 450px;
        object-fit: contain;
        margin: auto;
        align-self: center;
        /* margin-left: 20%; */
        margin-top: 30px;

    }

    /* .macControl {
        font-size: 10px !important;
    } */

}