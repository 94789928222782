.con-txt {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 23px;
    line-height: 110%;
    text-align: center;
    letter-spacing: 0.39em;
    color: #FBFBFF;
    margin-top: 60px;
    margin-bottom: 40px;
}

.mail-txt {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 110%;
    text-align: center;
    color: #FBFBFF;
}

.alll {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #FFFFFF;
    text-align: center;

}

.mobile-sidebar {
    position: fixed;
    top: 0;
    left: 0;
    max-width: 60% !important;
    height: 100vh;
    background-color: #f8f8f8;
    transition: transform 0.3s ease-in-out;
    z-index: 999;
    transform: translateX(-100%);
}

.mobile-sidebar.open {
    transform: translateX(0%);
}

.toggle-button {
    position: absolute;
    top: 10px;
    left: 10px;
    padding: 10px;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 20px;
    color: #333;
}

.sidebar-content {
    padding: 20px;
}

.sidebar-content ul {
    list-style-type: none;
    padding: 0;
}

.sidebar-content ul li {
    margin-bottom: 10px;
    font-size: 25px !important;
}

.sidebar-overlay {
    overflow: scroll;
    scroll-behavior: smooth;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(2px);
    /* Add blur effect */
}