.Ctexts {
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  color: #3C3C3C;
  text-align: left;
  word-wrap: break-word;
  max-width: 800px;
  margin-left: 20%;
  margin-top: 20%;
}

.subCar {
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 42px;
  letter-spacing: 0.02em;
  color: #2552B5;
  margin-top: 20px;
  margin-bottom: 40px;
}

.appBtn {
  background: #3C8FFF;
  border-radius: 10px;
  border-radius: 10px;
  color: white;
  font-size: 15px;
  border: none;
  /* max-width: 250px; */
  padding: 10px 12px 10px 12px;
}

.carrImgBg {
  /* max-width: 1080; */
  object-fit: "cover";
}

.main-sub-con {
  display: flex;
}

.job-role {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  color: #2E2E2E;
}

.job-sub-con {
  max-width: 1600px;
  margin-left: 20%;
}

.jobCards {
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  border: 1px solid grey;
  justify-content: center;
  align-items: center;
  width: 250px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: 600;
  margin-top: 15px;
  gap: 1rem;
  cursor: pointer;
}

.jobCard-con {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  max-width: 800px;
  gap: 1rem;
}

/* .job-sec {
  max-width: 600px;
  padding: 20px;
  align-self: center;
  border: 1px solid red;
} */
.ft-txt {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #3C3C3C;
  align-self: center;
}

@media only screen and (max-width: 768px) {
  .Ctexts {
    font-weight: 600;
    font-size: 20px;
    word-wrap: break-word;
    max-width: 700px;
    margin-left: 2%;
    margin-top: 10%;
  }

  .carrImgBg {
    max-width: 360px;
    object-fit: "cover";
    margin-top: -10px;
  }

  .main-sub-con {
    display: flex;
    flex-direction: column;
  }

  .job-sub-con {
    max-width: 1600px;
    margin-left: 2%;
  }
}

@media only screen and (min-device-width: 800px)and (max-width: 1680px) {
  .carrImgBg {
    max-width: 8900px;
    max-height: 600px;
    object-fit: "cover";
    margin: auto;
  }

  .main-sub-con {
    display: flex;
  }

  .Ctexts {
    font-weight: 700;
    font-size: 30px;
    color: #3C3C3C;
    text-align: left;
    word-wrap: break-word;
    max-width: 800px;
    margin-left: 10%;
    margin-top: 20%;
  }

  .job-sub-con {
    max-width: 1600px;
    margin-left: 20%;
  }
}